import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/store'
import { Route } from 'vue-router'

interface CachedRoute extends Route{
  title: any
}

export interface ITagsViewState {
  visitedViews: CachedRoute[]
  cachedViews: string[]
}

@Module({ dynamic: true, store, name: 'tagsView' })
class TagsView extends VuexModule implements ITagsViewState {

  public visitedViews: Array<CachedRoute> = []
  public cachedViews: Array<any> = []

  @Mutation
  private ADD_VISITED_VIEW(view: Route) {
    if (this.visitedViews.some(v => v.path === view.path)) return
    this.visitedViews.push(
      Object.assign({}, view, {
        title: view.meta ? view.meta.title : 'no-name'
      })
    )
  }

  @Mutation
  private ADD_CACHED_VIEW(view: Route) {
    if (this.cachedViews.includes(view.name)) return
    if (!view.meta || !view.meta.noCache) {
      this.cachedViews.push(view.name)
    }
  }

  @Mutation
  private DEL_VISITED_VIEW(view: Route) {
    for (const [i, v] of this.visitedViews.entries()) {
      if (v.path === view.path) {
        this.visitedViews.splice(i, 1)
        break
      }
    }
  }

  @Mutation
  private DEL_CACHED_VIEW(view: Route) {
    const index = this.cachedViews.indexOf(view.name)
    index > -1 && this.cachedViews.splice(index, 1)
  }

  @Mutation
  private DEL_OTHERS_VISITED_VIEWS(view: Route) {
    this.visitedViews = this.visitedViews.filter(v => {
      return (v.meta !== undefined && v.meta.affix) || v.path === view.path
    })
  }

  @Mutation
  private DEL_OTHERS_CACHED_VIEWS(view: Route) {
    const index = this.cachedViews.indexOf(view.name)
    if (index > -1) {
      this.cachedViews = this.cachedViews.slice(index, index + 1)
    } else {
      // if index = -1, there is no cached tags
      this.cachedViews = []
    }
  }

  @Mutation
  private DEL_ALL_VISITED_VIEWS() {
    // keep affix tags
    const affixTags = this.visitedViews.filter(tag => tag.meta !== undefined && tag.meta.affix)
    this.visitedViews = affixTags
  }

  @Mutation
  private DEL_ALL_CACHED_VIEWS() {
    this.cachedViews = []
  }

  @Mutation
  private UPDATE_VISITED_VIEW(view: Route) {
    for (let v of this.visitedViews) {
      if (v.path === view.path) {
        v = Object.assign(v, view)
        break
      }
    }
  }

  @Action
  addView(view: Route) {
    this.addVisitedView(view)
    this.addCachedView(view)
  }

  @Action
  addVisitedView(view: Route) {
    this.ADD_VISITED_VIEW(view)
  }

  @Action
  addCachedView(view: Route) {
    this.ADD_CACHED_VIEW(view)
  }

  @Action
  delView(view: Route) {
    return new Promise(resolve => {
      this.delVisitedView(view)
      this.delCachedView(view)
      resolve({
        visitedViews: [...this.visitedViews],
        cachedViews: [...this.cachedViews]
      })
    })
  }

  @Action
  delVisitedView(view: Route) {
    return new Promise(resolve => {
      this.DEL_VISITED_VIEW(view)
      resolve([...this.visitedViews])
    })
  }

  @Action
  delCachedView(view: Route) {
    return new Promise(resolve => {
      this.DEL_CACHED_VIEW(view)
      resolve([...this.cachedViews])
    })
  }

  @Action
  delOthersViews(view: Route) {
    return new Promise(resolve => {
      this.delOthersVisitedViews(view)
      this.delOthersCachedViews(view)
      resolve({
        visitedViews: [...this.visitedViews],
        cachedViews: [...this.cachedViews]
      })
    })
  }

  @Action
  delOthersVisitedViews(view: Route) {
    return new Promise(resolve => {
      this.DEL_OTHERS_VISITED_VIEWS(view)
      resolve([...this.visitedViews])
    })
  }

  @Action
  delOthersCachedViews(view: Route) {
    return new Promise(resolve => {
      this.DEL_OTHERS_CACHED_VIEWS(view)
      resolve([...this.cachedViews])
    })
  }

  @Action
  delAllViews() {
    return new Promise(resolve => {
      this.delAllVisitedViews()
      this.delAllCachedViews()
      resolve({
        visitedViews: [...this.visitedViews],
        cachedViews: [...this.cachedViews]
      })
    })
  }

  @Action
  delAllVisitedViews() {
    return new Promise(resolve => {
      this.DEL_ALL_VISITED_VIEWS()
      resolve([...this.visitedViews])
    })
  }

  @Action
  delAllCachedViews() {
    return new Promise(resolve => {
      this.DEL_ALL_CACHED_VIEWS()
      resolve([...this.cachedViews])
    })
  }

  @Action
  updateVisitedView(view: Route) {
    this.UPDATE_VISITED_VIEW(view)
  }
}

export const TagsViewModule = getModule(TagsView)