
import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'
import Logo from './Logo.vue'
import SidebarItem from './SidebarItem.vue'
import variables from '@/styles/_variables.scss'
import { UserModule } from '@/store/modules/user'

@Component({
  name: 'SideBar',
  components: {
    Logo,
    SidebarItem
  }
})
export default class extends Vue {
  get opened() {
    return AppModule.opened
  }

  get routes() {
    return UserModule.menu
  }

  get variables() {
    return variables
  }

  get activeMenu() {
    const route = this.$route
    const { meta, path } = route
    if (meta && meta.activeMenu) {
      return meta.activeMenu
    }
    return path
  }

  get isCollapse() {
    return !this.opened
  }

  showLogo() {
    return AppModule.setting.showLogo
  }
}
