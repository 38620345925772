/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  '3rd': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M810.667 128A85.333 85.333 0 01896 213.333V640a85.333 85.333 0 01-85.333 85.333H597.333v85.334h85.334V896H341.333v-85.333h85.334v-85.334H213.333A85.333 85.333 0 01128 640V213.333A85.333 85.333 0 01213.333 128zm0 85.333H213.333V640h597.334V213.333zM546.133 256a8.533 8.533 0 018.534 7.253l6.4 45.227a111.593 111.593 0 0128.586 16.64l42.667-17.067a7.68 7.68 0 0110.24 3.84l34.133 58.88a8.533 8.533 0 010 11.094L640.427 409.6v34.133l36.266 27.734a8.533 8.533 0 010 11.093l-34.133 58.88a7.68 7.68 0 01-10.24 3.84l-42.667-17.067a111.787 111.787 0 01-28.586 16.64l-6.4 45.227a8.533 8.533 0 01-8.534 7.253h-68.266a8.533 8.533 0 01-8.534-7.253l-6.4-45.227a111.787 111.787 0 01-28.586-16.64L391.68 545.28a8.107 8.107 0 01-10.24-3.84l-34.133-58.88a8.533 8.533 0 010-11.093l36.266-28.16V409.6l-36.266-27.733a8.533 8.533 0 010-11.094l34.133-58.88a7.68 7.68 0 0110.24-3.84l42.667 17.067a111.787 111.787 0 0128.586-16.64l6.4-45.227a8.533 8.533 0 018.534-7.253h68.266zm-22.186 34.133H499.2l-6.4 42.667a93.44 93.44 0 00-51.627 30.72l-42.666-17.92-12.8 22.187 35.84 26.453a94.72 94.72 0 000 61.44l-36.267 26.453 12.8 22.187 42.667-17.493a92.245 92.245 0 0051.626 29.866l6.4 42.667h25.6l6.4-42.667c20.054-4.266 38.144-14.933 51.627-30.293l42.667 17.493 12.8-22.186-36.267-26.454a94.72 94.72 0 000-61.44l35.84-26.453-12.8-22.187-42.667 17.92a90.453 90.453 0 00-51.626-30.293l-6.4-42.667zM512 358.4a68.267 68.267 0 110 136.533 68.267 68.267 0 010-136.533zm0 34.133a34.133 34.133 0 100 68.267 34.133 34.133 0 000-68.267z"/>'
  }
})
