/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'tao': {
    width: 201.563,
    height: 200,
    viewBox: '0 0 1032 1024',
    data: '<path pid="0" d="M849.92 51.2H174.08c-67.866 0-122.88 55.014-122.88 122.88v675.84c0 67.866 55.014 122.88 122.88 122.88h675.84c67.866 0 122.88-55.014 122.88-122.88V174.08c0-67.866-55.014-122.88-122.88-122.88zM311.311 284.887c30.536 0 55.25 22.257 55.25 49.536 0 27.264-24.714 49.495-55.25 49.495-30.49 0-55.198-22.226-55.198-49.495 0-27.274 24.714-49.536 55.198-49.536zm46.08 297.405c-14.571 45.149-10.951 28.406-68.802 154.077l-83.789-52.25s93.44-85.048 112.681-123.889c20.29-41.006-22.441-62.842-22.441-62.842l-64.169-40.008 34.662-53.453c48.425 35.937 52.05 38.958 84.736 71.675 25.242 25.344 22.063 60.544 7.122 106.69zm455.506 58.645C796.56 794.22 597.13 737.29 597.13 737.29l10.814-43.899 46.162 9.949c85.478 5.432 77.138-69.243 77.138-69.243V417.085c.716-82.37-77.481-90.93-217.79-40.74l32.691 8.827c-2.872 9.652-13.49 24.658-26.982 41.145h187.73v37.96H601.21v47.528h105.226v37.96H601.216v79.667c15.703-5.273 30.449-12.39 43.218-21.857l-9.329-34.76 49.73-15.431 41.273 101.324-61.128 25.411-10.88-40.397c-27.238 20.926-84.07 51.144-183.111 48.476-105.892 2.709-78.746-119.388-78.746-119.388l2.709-1.418h74.409c-.487 15.77-6.897 41.35 1.91 55.276 7.147 11.427 25.804 13.414 37.677 13.946 1.321.18 2.74.18 4.127.23v-91.079h-107.98v-37.96h107.98v-47.534H485.35c-24.304 25.78-46.438 47.12-46.438 47.12l-32.589-28.503c23.148-24.34 46.305-62.853 60.55-88.663a1370.255 1370.255 0 00-35.887 15.227 554.726 554.726 0 01-41.282 46.295c.568.86-53.243-30.147-53.243-30.147 55.618-47.514 86.81-149.228 86.81-149.228L500.684 298s-6.508 15.089-19.635 38.114c309.75-87.706 327.72 53.77 327.72 53.77s20.409 97.725 4.127 251.054z" _fill="#FF5000"/>'
  }
})
