import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'
import { getAppSetting, setAppSetting } from '@/utils/localstorage'
import store from '@/store'

export enum DeviceType {
  Mobile,
  Desktop,
}

export interface IAppState {

  device: DeviceType
  opened: boolean
  withoutAnimation: boolean

  setting: {
    needTagsView: boolean
    logoTitle: string
    logoAdress: string
    showLogo: boolean
  }
  
}

@Module({ dynamic: true, store, name: 'app' })
class App extends VuexModule implements IAppState {

  public opened = true
  public withoutAnimation = false

  public setting = getAppSetting() || {
    needTagsView: true,
    logoTitle: 'Jstart Cloud Admin',
    logoAdress: 'https://wpimg.wallstcn.com/69a1c46c-eb1c-4b46-8bd4-e9e686ef5251.png',
    showLogo: true
  }

  public device = DeviceType.Desktop

  @Mutation
  private TOGGLE_SIDEBAR(withoutAnimation: boolean) {
    this.opened = !this.opened
    this.withoutAnimation = withoutAnimation
  }

  @Mutation
  private CLOSE_SIDEBAR(withoutAnimation: boolean) {
    this.opened = false
    this.withoutAnimation = withoutAnimation
  }

  @Mutation
  private TOGGLE_DEVICE(device: DeviceType) {
    this.device = device
  }

  @Mutation
  private SAVE_SETTING(config: any) {
    this.setting = config
    setAppSetting(this.setting);
  }

  @Action
  public ToggleSideBar(withoutAnimation: boolean) {
    this.TOGGLE_SIDEBAR(withoutAnimation)
  }

  @Action
  public CloseSideBar(withoutAnimation: boolean) {
    this.CLOSE_SIDEBAR(withoutAnimation)
  }

  @Action
  public ToggleDevice(device: DeviceType) {
    this.TOGGLE_DEVICE(device)
  }

  @Action
  public setSetting(config: any) {
    this.SAVE_SETTING(config)
  }
}

export const AppModule = getModule(App)
