/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'wallet': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M641.968 124.48h172.126C911.622 124.48 991 206.12 991 305.684v504.112C991 909.361 911.622 991 814.094 991v-52.2c68.366 0 124.706-57.945 124.706-129.004V305.684c0-71.059-56.34-129.004-124.706-129.004H601.741l-4.555-20.416C588.108 115.578 552.845 86.2 512.5 86.2H173.2c-47.621 0-87 40.507-87 90.236v633.36c0 71.063 56.327 129.004 124.688 129.004V991C113.363 991 34 909.363 34 809.796v-633.36C34 98.203 96.415 34 173.2 34h339.3c58.072 0 108.919 37.061 129.468 90.48z" _fill="#333"/><path pid="1" d="M644 508.5c0 14.071-11.18 25.5-24.991 25.5S594 522.571 594 508.5c0-14.088 11.198-25.5 25.009-25.5 13.81 0 24.991 11.412 24.991 25.5" _fill="#333"/><path pid="2" d="M938.8 306.886c0-70.934-56.34-128.778-124.706-128.778H210.888c-68.35 0-124.688 57.846-124.688 128.778v503.228c0 70.932 56.338 128.778 124.688 128.778h31.425c14.414 0 26.1 11.664 26.1 26.054 0 14.39-11.686 26.054-26.1 26.054h-31.425C113.375 991 34 909.5 34 810.114V306.886C34 207.499 113.375 126 210.888 126h603.206C911.622 126 991 207.496 991 306.886v148.63H594.193c-30.347 0-55.593 25.92-55.593 57.823 0 31.904 25.246 57.823 55.593 57.823H991v238.952C991 909.504 911.622 991 814.094 991H468.513c-14.415 0-26.1-11.665-26.1-26.054 0-14.39 11.685-26.054 26.1-26.054h345.581c68.366 0 124.706-57.844 124.706-128.778V623.271H594.193c-59.51 0-107.793-49.572-107.793-109.932s48.283-109.931 107.793-109.931H938.8v-96.522z" _fill="#333"/>'
  }
})
