/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'realname-auth-audit': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M473.344 752.742c0-25.6 3.072-50.483 8.704-74.393H106.906v-71.424H508.16a323.85 323.85 0 01137.318-139.571H509.952V395.93h321.69v36.25c74.803 8.242 141.875 42.137 192.358 92.569V82.995a35.738 35.738 0 00-35.738-35.737H35.738A35.738 35.738 0 000 82.995V797.39a35.738 35.738 0 0035.738 35.737H483.43a322.57 322.57 0 01-10.086-80.384zm36.608-526.848h321.69v71.424h-321.69v-71.424zM202.24 297.37c0-52.634 42.598-95.284 95.232-95.284 33.331 0 64.205 17.408 81.51 45.876 27.239 45.004 12.903 103.577-32.102 130.816 49.152 20.633 85.862 71.321 93.491 132.864H154.573c7.629-61.543 44.339-112.23 93.542-132.864-28.518-17.255-45.875-48.128-45.875-81.408z" _fill="#333"/><path pid="1" d="M795.904 976.742c-123.187 0-224-100.812-224-224s100.813-224 224-224 224 100.813 224 224-100.813 224-224 224zm0-384c-22.426 0-39.987 14.388-39.987 32v164.813c0 17.613 17.613 32 39.987 32 22.426 0 39.987-14.387 39.987-32V624.742c0-17.612-17.613-32-39.987-32zm0 331.162c22.426 0 39.987-17.613 39.987-39.987s-17.613-39.987-39.987-39.987c-22.426 0-39.987 17.612-39.987 39.987s17.561 39.987 39.987 39.987z" _fill="#333"/>'
  }
})
