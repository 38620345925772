/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'setting': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M634.425 994.133h-244.85a267.406 267.406 0 01-231.31-134.485L35.669 645.774a270.962 270.962 0 010-268.686l122.596-213.874A267.577 267.577 0 01389.575 28.73h244.708a267.406 267.406 0 01231.31 134.485l122.595 213.874a270.962 270.962 0 010 268.686L865.593 859.648a267.179 267.179 0 01-231.168 134.485zm-402.83-177.095a182.756 182.756 0 00157.98 91.876h244.708a182.585 182.585 0 00157.98-91.876L914.83 603.164a185.4 185.4 0 000-183.608L792.235 205.653a182.756 182.756 0 00-157.924-91.875H389.604a182.585 182.585 0 00-157.98 91.875L109.055 419.556a185.4 185.4 0 000 183.608l122.596 213.903zM512 729.998c-119.751 0-217.202-98.133-217.202-218.71S392.248 292.58 512 292.58s217.202 98.133 217.202 218.709-97.45 218.71-217.202 218.71zm0-352.199c-73.074 0-132.58 59.933-132.58 133.49 0 73.557 59.506 133.49 132.58 133.49 73.074 0 132.58-59.933 132.58-133.49 0-73.557-59.506-133.49-132.58-133.49z"/>'
  }
})
