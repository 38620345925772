import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators'
import { getToken, setToken, removeToken, setUserInfo, getUserInfo, getMenus, setMenus, removeUserInfo, removeMenus } from '@/utils/localstorage'
import store from '@/store'
import { adminSmsToken, loginAccount, logoutAccount } from '@/api/uaa/member'
import { getCurrentUser } from '@/api/system/user'
import { AppModule } from './app'

export interface IUserState {
  token: string
  isAlertNoLogin: boolean
  currUserInfo: any
  menu: any
}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements IUserState {
  public token = getToken() || ''
  public isAlertNoLogin = false
  public currUserInfo:any = getUserInfo() || {}
  public menu:any = getMenus() || []

  @Mutation
  private SET_USERINFO(currUserInfo: any) {
    this.currUserInfo = currUserInfo
    setUserInfo(currUserInfo)
  }

  @Mutation
  private SET_ISALERTNOlOGIN(isAlertNoLogin: boolean) {
    this.isAlertNoLogin = isAlertNoLogin
  }

  @Mutation
  private SET_TOKEN(token: string) {
    this.token = token
  }

  @Mutation
  private SET_MENU(menu: any) {
    this.menu = menu
    setMenus(menu)
  }

  @Action
  public async Login(userInfo: { username: string, password: string, code: string, key: string }) {
    const { username, password, code, key } = userInfo
    const { data } = await loginAccount({ username, password, code, key })
    setToken(data.token)
    this.SET_TOKEN(data.token)
    this.SET_USERINFO(data.user)
    this.SET_MENU(data.menus)
    AppModule.setSetting(data.webConfig)
  }

  @Action
  public async SmsLogin(userInfo: { phone: string, smsCode: string }) {
    const { phone, smsCode } = userInfo
    const { data } = await adminSmsToken({ phone, smsCode })
    setToken(data.token)
    this.SET_TOKEN(data.token)
    this.SET_USERINFO(data.user)
    this.SET_MENU(data.menus)
    AppModule.setSetting(data.webConfig)
  }
  
  @Action
  public ResetToken() {
    removeToken()
    this.SET_TOKEN('')
  }

  @Action
  public RefreshToken(refreshToken: string) {
    removeToken()
    setToken(refreshToken)
    this.SET_TOKEN(refreshToken)
  }

  @Action
  public async GetUserInfo() {
    if (this.token === '') {
      throw Error('GetUserInfo: token is undefined!')
    }
    const { data } = await getCurrentUser()
    if (!data) {
      throw Error('Verification failed, please Login again.')
    }
    this.SET_USERINFO(data)
  }

  @Action
  public async LogOut() {
    if (this.token === '') {
      throw Error('LogOut: token is undefined!')
    }
    return logoutAccount().then(res=>{
      removeToken()
      removeUserInfo()
      removeMenus()
      this.SET_TOKEN('')
      return res;
    });
  }

  @Action
  public setNoLoginAlert(isAlertNoLogin: boolean) {
    this.SET_ISALERTNOlOGIN(isAlertNoLogin)
  }
}

export const UserModule = getModule(User)
