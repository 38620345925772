/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'order-list': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M745.728 496.747c-1.548-21.933-18.755-38.469-40.031-38.469H313.372c-21.104 0-38.94 18.02-38.94 39.346 0 21.321 17.836 39.336 38.94 39.336h393.439c21.112 0 38.948-18.015 38.948-39.336l-.03-.877zM419.26 191.314h182.786c27.755 0 51.205-26.783 51.205-58.485 0-32.247-23.473-58.482-52.323-58.482H419.26c-28.85 0-52.319 26.234-52.319 58.482s23.47 58.485 52.319 58.485zM706.81 311.909H313.372c-21.104 0-38.94 18.015-38.94 39.345 0 21.326 17.836 39.341 38.94 39.341h393.439c21.112 0 38.948-18.015 38.948-39.341-.001-21.33-17.837-39.345-38.95-39.345zM705.697 617.027H313.372c-21.104 0-38.94 18.02-38.94 39.345 0 21.322 17.836 39.337 38.94 39.337h393.439c21.112 0 38.948-18.015 38.948-39.337l-.03-.877c-1.549-21.931-18.757-38.468-40.032-38.468z" _fill="#5D5D5D"/><path pid="1" d="M884.997 134.835c-20.917-22.63-50.93-34.593-86.794-34.593H745.82c-20.875 0-43.402 13.752-43.402 35.962 0 20.994 21.497 41.599 43.402 41.599h52.384c24.411 0 40.188 15.932 40.188 40.595v609.109c0 24.662-15.778 40.595-40.188 40.595h-563.96c-25.28 0-53.558-17.359-53.558-40.595V218.399c0-24.663 15.773-40.595 40.187-40.595h65.758c19.294 0 32.256-16.72 32.256-41.599 0-22.519-12.059-35.962-32.256-35.962h-65.758c-73.24 0-116.962 44.593-116.962 119.282v602.352c0 75.196 48.097 123.786 122.534 123.786h566.183c79.022 0 122.54-43.963 122.54-123.786V219.524c0-34.052-10.431-63.337-30.17-84.69z" _fill="#5D5D5D"/>'
  }
})
