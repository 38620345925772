/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'reward': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M725.3 650.5v293.2A21.3 21.3 0 01693 962L512 853.3 331 961.9a21.3 21.3 0 01-32.3-18.3V650.5a341.3 341.3 0 11426.7 0zm-341.3 50v130.1l128-76.8 128 76.8V700.5a340.4 340.4 0 01-128 24.8 340.4 340.4 0 01-128-24.8zM512 640a256 256 0 100-512 256 256 0 000 512z"/>'
  }
})
