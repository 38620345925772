// App
const appSettingKey = 'jstart_vue_admin_app_setting'
export const getAppSetting = () => {
    const res = localStorage.getItem(appSettingKey)
    return res ? JSON.parse(res) : res
}
export const setAppSetting = (appSetting: string) => {
    const res = JSON.stringify(appSetting)
    localStorage.setItem(appSettingKey, res)
}

// User-Token
const tokenKey = 'jstart_vue_admin_access_token'
export const getToken = () => localStorage.getItem(tokenKey)
export const setToken = (token: string) => localStorage.setItem(tokenKey, token)
export const removeToken = () => localStorage.removeItem(tokenKey)

// User-UserInfo
const userInfoKey = 'jstart_vue_admin_curr_userInfo'
export const getUserInfo = () =>  {
     const res = localStorage.getItem(userInfoKey)
     return res ? JSON.parse(res) : res
}
export const setUserInfo = (userInfo: any) => {
    const res = JSON.stringify(userInfo)
    localStorage.setItem(userInfoKey, res)
}
export const removeUserInfo = () => localStorage.removeItem(userInfoKey)

// User-UserMenu
const menusKey = 'jstart_vue_admin_menus'
export const getMenus = () => {
    const res = localStorage.getItem(menusKey)
    return res ? JSON.parse(res) : res
}
export const setMenus = (menus: any) => {
    const res = JSON.stringify(menus)
    localStorage.setItem(menusKey, res)
}
export const removeMenus = () => localStorage.removeItem(menusKey)
