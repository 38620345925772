import Vue from 'vue'
import Router, { RouteConfig } from 'vue-router'

const modulesFiles = require.context("@/router/modules",false,/\.ts$/)

const modulesRoutes: any[] = [];

modulesFiles.keys().forEach(key=>{
  const value = modulesFiles(key)
  const data = value.default
  if(data) modulesRoutes.push(data)
})

Vue.use(Router)

const routes: Array<RouteConfig> = [
  {
    path: '/redirect',
    component: () => import('@/layout/index.vue'),
    meta: { hidden: true },
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index.vue')
      }
    ]
  },
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue'),
    meta: { hidden: true }
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
    meta: { hidden: true }
  },
  {
    path: '*',
    redirect: '/404',
    meta: { hidden: true }
  },
  ...modulesRoutes
]

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

export default router
