
import { Component, Vue, Watch } from 'vue-property-decorator'
import ScrollPane from './ScrollPane.vue'
import { TagsViewModule } from '@/store/modules/tagsView'
import path from 'path'

@Component({
  name: 'TagsView',
  components: {
    ScrollPane
  }
})
export default class TagsView extends Vue {

  visible= false
  top= 0
  left= 0
  selectedTag: any = {}
  affixTags: any[] = []

  get visitedViews() {
    return TagsViewModule.visitedViews
  }

  get routes() {
    return (this.$router as any).options.routes
  }

  mounted() {
    this.initTags()
    this.addTags()
  }

  @Watch("$route")
  routeChange(){
    this.addTags()
    this.moveToCurrentTag()
  }

  @Watch("visible")
  visibleChange(value:any){
    if (value) {
      document.body.addEventListener('click', this.closeMenu)
    } else {
      document.body.removeEventListener('click', this.closeMenu)
    }
  }

  isActive(route: any) {
    return route.path === this.$route.path
  }

  isAffix(tag: any) {
    return tag.meta && tag.meta.affix
  }

  filterAffixTags(routes: any, basePath = '/') {
    let tags: any[] = []
    routes.forEach((route: any) => {
      if (route.meta && route.meta.affix) {
        const tagPath = path.resolve(basePath, route.path)
        tags.push({
          fullPath: tagPath,
          path: tagPath,
          name: route.name || route.meta.title,
          meta: { ...route.meta }
        })
      }
      if (route.children) {
        const tempTags = this.filterAffixTags(route.children, route.path)
        if (tempTags.length >= 1) {
          tags = [...tags, ...tempTags]
        }
      }
    })
    return tags
  }

  initTags() {
    const affixTags = this.affixTags = this.filterAffixTags(this.routes)
    for (const tag of affixTags) {
      // Must have tag name
      if (tag.name) {
        TagsViewModule.addVisitedView(tag)
      }
    }
  }

  addTags() {
    const name = this.$route.name || this.$route.meta?.title
    if (name) {
      TagsViewModule.addView(this.$route)
    }
    return false
  }

  moveToCurrentTag() {
    const tags = this.$refs.tag as any[]
    this.$nextTick(() => {
      for (const tag of tags) {
        if (tag.to.path === this.$route.path) {
          (this.$refs.scrollPane as ScrollPane).moveToTarget(tag)
          // when query is different then update
          if (tag.to.fullPath !== this.$route.fullPath) {
            TagsViewModule.updateVisitedView(this.$route)
          }
          break
        }
      }
    })
  }

  refreshSelectedTag(view: any) {
    TagsViewModule.delCachedView(view).then((res) => {
      const { fullPath } = view
      this.$nextTick(() => {
        this.$router.replace({
          path: '/redirect' + fullPath
        })
      })
    })
  }

  closeSelectedTag(view: any) {
    TagsViewModule.delView(view).then((res: any) => {
      if (this.isActive(view)) {
        this.toLastView(res.visitedViews, view)
      }
    })
  }

  closeOthersTags() {
    this.$router.push(this.selectedTag)
    TagsViewModule.delOthersViews(this.selectedTag).then(() => {
      this.moveToCurrentTag()
    })
  }

  closeAllTags(view: any) {
    TagsViewModule.delAllViews().then((res: any) => {
      if (this.affixTags.some(tag => tag.path === view.path)) {
        return
      }
      this.toLastView(res.visitedViews, view)
    })
  }

  toLastView(visitedViews: any, view: any) {
    const latestView = visitedViews.slice(-1)[0]
    if (latestView) {
      this.$router.push(latestView.fullPath)
    } else {
      // now the default is to redirect to the home page if there is no tags-view,
      // you can adjust it according to your needs.
      if (view.name === 'Dashboard') {
        // to reload home page
        this.$router.replace({ path: '/redirect' + view.fullPath })
      } else {
        this.$router.push('/')
      }
    }
  }

  openMenu(tag: any, e: any) {
    const menuMinWidth = 105
    const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
    const offsetWidth = (this.$el as HTMLElement).offsetWidth // container width
    const maxLeft = offsetWidth - menuMinWidth // left boundary
    const left = e.clientX - offsetLeft + 15 // 15: margin right

    if (left > maxLeft) {
      this.left = maxLeft
    } else {
      this.left = left
    }

    this.top = e.clientY
    this.visible = true
    this.selectedTag = tag
  }

  closeMenu() {
    this.visible = false
  }

  handleScroll() {
    this.closeMenu()
  }

}
