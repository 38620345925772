import jsrsasign from 'jsrsasign'
import { appSecret, privateKey } from './encryptConfig'

/* JSEncrypt签名 */
export const rsaSignData = function(data:object) {
     //这里必须使用这个标准格式所有做了一个拼接
  const priK = "-----BEGIN PRIVATE KEY-----" + privateKey + "-----END PRIVATE KEY-----";
  const rsa = jsrsasign.KEYUTIL.getKey(priK);
  //后端算法与前端需要一致
  const sig = new jsrsasign.KJUR.crypto.Signature({ alg: "SHA256withRSA" });
  // 初始化
  sig.init(rsa)
  // 传入待加密字符串
  sig.updateString(packageData(data))
  // 生成密文
  return jsrsasign.hextob64(sig.sign());
    // var jsencrypt = new JSEncrypt()
    // jsencrypt.setPublicKey(privateKey)
    //  如果是对象/数组的话，需要先JSON.stringify转换成字符串
    // var result = jsencrypt.sign(packageData(data),()=>"RSA","SHA256withRSA")
    // return result
}

/* 组装签名数据 */
const packageData = function(data:any) {
    const keys = Object.keys(data).sort();
    let dataStr = "";
    keys.forEach((key:string)=>{
        let value = data[key];
        if(value === undefined || value === null) value = "";
        if(value instanceof Object){
            value = JSON.stringify(data[key]);
        }
        dataStr += key + "=" + value + "&";
    });
    if(dataStr.length > 0){
        dataStr = dataStr.substring(0,dataStr.length - 1)
    }
    return dataStr + '&key=' + appSecret
}