import { RouteConfig } from "vue-router"
import Layout from '@/layout/index.vue'

const setting: RouteConfig = {
    path: '/activity',
    component: Layout,
    redirect: 'noredirect',
    meta: {
        title: '活动管理',
        icon: 'activity'
    },
    children: [
        {
            path: 'dividendCoin',
            component: () => import('@/views/activity/coinDividend/config/index.vue'),
            redirect: '/activity/dividendCoin/taobaoConfig',
            meta: {
              title: '瓜分金币',
              icon: 'activity-coin'
            },
            children: [
              {
                path: 'config',
                component: () => import('@/views/activity/coinDividend/config/index.vue'),
                meta: {
                  title: '活动配置'
                }
              }
            ]
        },
        {
            path: 'test',
            component: () => import('@/views/dashboard/index.vue'),
            meta: {
                title: '测试',
                icon: 'activity-coin'
            }
        }
    ]
}

export default setting;