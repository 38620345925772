/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'logs': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M269.845 81.43h44.82v166.627h-44.82zM677.14 491.72c52.335 0 102.092 19.937 140.106 56.138 38.126 36.31 60.461 85.284 62.891 137.9 2.506 54.277-16.274 106.28-52.881 146.432-36.607 40.152-86.66 63.644-140.937 66.15-3.18.148-6.401.222-9.576.222-52.341 0-102.102-19.937-140.114-56.137-38.126-36.309-60.461-85.284-62.891-137.902-2.506-54.277 16.274-106.28 52.881-146.432 36.607-40.152 86.66-63.644 140.936-66.15a208.123 208.123 0 019.576-.22h.009m-.01-44.823c-3.86 0-7.746.089-11.642.269-136.952 6.324-242.848 122.47-236.525 259.422 6.143 133.056 115.942 236.794 247.78 236.794 3.859 0 7.747-.089 11.642-.269 136.952-6.322 242.847-122.47 236.525-259.422-6.144-133.057-115.943-236.799-247.78-236.794z"/><path pid="1" d="M490.265 891.11a272.361 272.361 0 01-32.683-37.37H180.453c-20.912 0-37.927-17.013-37.927-37.925V225.551c0-20.912 17.014-37.927 37.927-37.927H732.8c20.912 0 37.926 17.014 37.926 37.927v215.605a268.605 268.605 0 0144.821 21.463V225.551c0-45.7-37.047-82.746-82.747-82.746H180.453c-45.7 0-82.747 37.047-82.747 82.746v590.264c0 45.7 37.048 82.747 82.747 82.747h317.98a273.587 273.587 0 01-8.168-7.451z"/><path pid="2" d="M770.725 489.616a225.244 225.244 0 0144.821 27.232v-.214a225.183 225.183 0 00-44.82-27.114v.096zm41.866 288.914H646.82V576.106h44.82V733.71h120.95zM209.55 380.121h498.256v44.822H209.55zm391.131-298.69h44.822v166.626h-44.822zm-193.84 630.743H209.552v44.821h203.865a272.351 272.351 0 01-6.573-44.82zm44.1-166.026H209.55v44.821h217.435a268.707 268.707 0 0123.955-44.821z"/>'
  }
})
