/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'finance': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M231.488 164a32 32 0 0132-32h488.8a32 32 0 110 64h-488.8a32 32 0 01-32-32zm-50.56 96.464a32 32 0 0132-32h594.16a32 32 0 110 64H212.896a32 32 0 01-32-32zM84 435.184c0-56.352 45.76-101.872 102.048-101.872h647.904c56.288 0 102.048 45.52 102.048 101.856v350.976C936 842.464 890.24 888 833.952 888H186.048C129.76 888 84 842.48 84 786.144V435.168zm102.048-37.872A37.952 37.952 0 00148 435.168v350.976A37.952 37.952 0 00186.048 824h647.904A37.952 37.952 0 00872 786.144V435.168a37.952 37.952 0 00-38.048-37.856H186.048zm185.376 56.576a32 32 0 0143.84-11.28l98.08 57.872 95.312-57.696a32 32 0 1133.136 54.752l-96.256 58.272v9.12H659.6a32 32 0 110 64H545.536v26.016l114.288.832a32 32 0 01-.464 64l-113.824-.832v40.592a32 32 0 11-64 0V718.72H364.64a32 32 0 010-64h116.912v-25.792H364.64a32 32 0 110-64h116.912v-8.896l-98.816-58.32a32 32 0 01-11.296-43.824z"/>'
  }
})
