
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'UserInfo'
})
export default class UserInfo extends Vue {
  @Prop({
    required: true,
    default: false
  }) dialogUserInfoVisible!: boolean;
  @Prop({ required: true }) readonly infoParams!: any;

  get infoTitle() {
    return "【" + this.infoParams.username + "】";
  }

  get dialogVisible() {
    return this.dialogUserInfoVisible;
  }

  set dialogVisible(val) {
    this.$emit('update:dialogUserInfoVisible', val)
  }
}
