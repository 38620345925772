/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'download': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M1024 748.544q0 25.6-8.704 48.128t-24.064 40.96-36.352 30.208-45.568 15.872v2.048H222.208q-2.048 0-2.56.512t-2.56.512-3.072-.512-3.072-.512H204.8v-1.024q-43.008-2.048-80.384-19.456t-65.024-46.592-43.52-67.584T0 669.696 15.872 588.8t43.008-66.56 63.488-47.104 78.336-21.504q7.168-66.56 36.864-124.416t76.288-100.864 107.008-67.584 129.024-24.576q72.704 0 137.216 27.648t112.128 75.776 75.264 112.128 27.648 136.704q0 32.768-6.144 64t-17.408 59.904q2.048 0 4.608-.512t4.608-.512q28.672 0 53.248 10.752t43.008 29.184 29.184 43.52 10.752 53.76zM687.104 596.992q12.288-20.48 7.168-23.552t-25.6-3.072q-12.288 0-34.304-.512t-33.28-.512q-16.384 0-20.992-13.312t-4.608-36.864q0-32.768-.512-52.736t-.512-43.52q0-26.624-3.584-38.912t-29.184-12.288q-18.432 0-27.648.512t-25.6.512q-27.648 0-34.304 15.872t-6.656 31.232v32.768q0 13.312.512 25.6t.512 25.6v29.696q0 16.384-4.096 25.088t-19.456 8.704q-6.144 1.024-16.896 1.536t-22.016 1.024-21.504.512H348.16q-21.504 0-25.6 13.312t13.312 33.792q17.408 21.504 37.376 46.592t39.424 50.688 37.376 49.664 33.28 41.472q27.648 30.72 53.248-1.024 15.36-17.408 35.84-45.056t41.984-57.856 40.96-58.368 31.744-46.592z"/>'
  }
})
