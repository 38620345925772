/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'activity-coin': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M0 0h1024v1024H0z" _fill="#D8D8D8" opacity=".01"/><path pid="1" d="M373.504 513.728c-4.288.192-8.32.768-12.608.768-115.84 0-212.48-54.144-244.096-128-7.04 16.32-11.904 33.28-11.904 51.2 0 93.952 103.552 170.112 235.008 177.728 5.76-36.16 17.152-70.4 33.6-101.696" _fill="#E39200"/><path pid="2" d="M335.296 668.096c0-.512 0-1.024.128-1.472-104.192-7.36-189.376-58.24-218.624-126.528-7.04 16.32-11.904 33.28-11.904 51.2 0 96.64 109.568 174.976 246.4 178.56a331.072 331.072 0 01-16-101.76" _fill="#E39200"/><path pid="3" d="M372.864 820.992c-4.096.128-7.936.704-11.968.704-115.84 0-212.48-54.144-244.096-128-7.04 16.32-11.904 33.28-11.904 51.2 0 98.944 114.56 179.2 256 179.2 29.376 0 57.024-4.224 83.328-10.56a331.712 331.712 0 01-71.36-92.544m39.232-562.496v102.4h-51.2v-102.4h-51.2v-51.2h102.4v51.2zm-51.2-153.6c-141.44 0-256 80.256-256 179.2 0 98.944 114.56 179.2 256 179.2 141.376 0 256-80.256 256-179.2 0-98.944-114.624-179.2-256-179.2z" _fill="#E39200"/><path pid="4" d="M719.296 770.496h-51.2v-102.4h-51.2v-51.2h51.2v-51.2h51.2v204.8zm-51.2-358.4a256 256 0 100 512 256 256 0 000-512z" _fill="#E39200"/>'
  }
})
