
import { Component, Prop, Vue } from 'vue-property-decorator'
import SidebarItemLink from './SidebarItemLink.vue'

@Component({
  // Set 'name' here to prevent uglifyjs from causing recursive component not work
  // See https://medium.com/haiiro-io/element-component-name-with-vue-class-component-f3b435656561 for detail
  name: 'SidebarItem',
  components: {
    SidebarItemLink,
  }
})
export default class extends Vue {
  @Prop({ required: true }) item!: any
  @Prop({ default: false }) isCollapse!: boolean
  @Prop({ default: true }) isFirstLevel!: boolean
}
