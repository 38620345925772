/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'share': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M232.676 1023.81c-61.232 0-118.633-23.665-161.622-66.635l-4.229-4.21c-89.088-89.126-89.088-234.117 0-323.243l181.097-181.077c3.47 25.372 9.557 50.422 18.09 74.6L113.19 676.087c-63.526 63.544-63.526 166.93 0 230.494l4.267 4.267c30.72 30.72 71.642 47.635 115.219 47.635s84.517-16.934 115.256-47.673l228.883-228.883c63.526-63.545 63.526-166.95 0-230.495l-4.266-4.247a149.623 149.623 0 00-6.41-6.05l46.403-46.402c2.18 2.01 4.304 4.04 6.371 6.106l4.267 4.248c89.088 89.088 89.069 234.079-.02 323.205L394.298 957.175c-42.97 42.97-100.371 66.635-161.621 66.635zm178.782-394.543a244.947 244.947 0 01-6.352-6.106l-4.248-4.248c-43.084-43.103-66.825-100.485-66.825-161.602 0-61.118 23.741-118.5 66.825-161.603L629.722 66.825C672.673 23.855 730.074.19 791.324.19s118.652 23.665 161.622 66.635l4.172 4.21c43.14 43.103 66.882 100.523 66.882 161.64 0 61.118-23.723 118.52-66.825 161.622L776.097 575.393a378.771 378.771 0 00-18.09-74.62l152.822-152.84c63.545-63.545 63.545-166.95 0-230.495l-4.286-4.267c-30.72-30.72-71.642-47.635-115.219-47.635-43.557 0-84.499 16.934-115.237 47.692L447.204 342.092c-30.74 30.739-47.673 71.68-47.673 115.257s16.934 84.518 47.673 115.238l4.228 4.247a131.869 131.869 0 006.429 6.069l-46.403 46.364z" _fill="#272636"/>'
  }
})
