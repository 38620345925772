/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'user-manager': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" _fill="#333" d="M634.9 958.2H143.4c-67.8 0-122.9-55.1-122.9-122.9v-81.9c0-158.1 128.6-286.7 286.7-286.7H471c158.1 0 286.7 128.6 286.7 286.7v81.9c.1 67.8-55.1 122.9-122.8 122.9zM307.2 548.6c-112.9 0-204.8 91.9-204.8 204.8v81.9c0 22.6 18.4 41 41 41h491.5c22.6 0 41-18.4 41-41v-81.9c0-112.9-91.9-204.8-204.8-204.8H307.2zm606 307.2c-22.6 0-41-18.3-41-41s18.3-41 41-41c4.6 0 8.4-3.9 8.4-8.4V716c0-59.1-48.1-107.2-107.2-107.2-22.6 0-41-18.3-41-41 0-22.6 18.3-41 41-41 104.3 0 189.1 84.8 189.1 189.1v49.4c0 50-40.5 90.5-90.3 90.5zM389.1 147.7c45.2 0 81.9 38.4 81.9 85.6S434.3 319 389.1 319s-81.9-38.4-81.9-85.6 36.7-85.7 81.9-85.7m0-81.9c-90.5 0-163.8 75-163.8 167.6 0 92.5 73.4 167.6 163.8 167.6S553 325.9 553 233.3c0-92.5-73.4-167.5-163.9-167.5zm.8 573.8c-22.5 0-41 18.4-41 41v81.9c0 22.5 18.4 41 41 41s41-18.4 41-41v-81.9c0-22.6-18.4-41-41-41zm336.5-172.9c-22.6 0-41-18.3-41-41 0-22.6 18.3-41 41-41 33.4 0 60.5-30.2 60.5-67.4s-27.2-67.4-60.5-67.4c-2.1 0-4.2.1-6.3.4-22.5 2.6-42.7-13.7-45.2-36.2-2.5-22.5 13.7-42.7 36.2-45.2 5-.6 10.1-.9 15.3-.9 78.5 0 142.5 67 142.5 149.3s-64 149.4-142.5 149.4z"/>'
  }
})
