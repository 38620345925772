
import { AppModule } from '@/store/modules/app'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'Logo'
})
export default class extends Vue {
  @Prop({ required: true, default: false }) collapse!: boolean

  get logo() {
    return AppModule.setting.logoAdress;
  }

  get title(){
    return AppModule.setting.logoTitle;
  }

}
