/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'signed': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M946.968 1023.963H77.031c-24.858 0-44.997-21.5-44.997-47.997V176.025c0-26.497 20.14-47.996 44.997-47.996h89.993v47.996c0 61.87 47.012 111.993 104.992 111.993 57.98 0 104.994-50.123 104.994-111.993V128.03h269.981v47.996c0 61.87 47.01 111.993 104.993 111.993 57.98 0 104.991-50.123 104.991-111.993V128.03h89.995c24.858 0 44.997 21.499 44.997 47.996v799.94c-.002 26.497-20.14 47.997-44.999 47.997zM901.97 384.01H122.03v543.96H901.97V384.01zM362.01 607.994c12.421 0 23.67 5.375 31.825 14.063l73.167 78.054 178.176-190.048c8.14-8.687 19.388-14.061 31.81-14.061 24.857 0 44.997 21.497 44.997 47.994 0 13.248-5.03 25.25-13.187 33.935L498.812 801.915c-8.14 8.688-19.387 14.062-31.81 14.062-12.421 0-23.67-5.374-31.826-14.062L330.185 689.927c-8.14-8.685-13.17-20.69-13.17-33.937-.001-26.5 20.138-47.996 44.994-47.996zM751.981 224.02c-24.842 0-44.999-21.497-44.999-47.996V48.034c0-26.499 20.155-47.997 45-47.997 24.857 0 44.996 21.498 44.996 47.997v127.99c0 26.5-20.139 47.996-44.997 47.996zm-479.966 0c-24.857 0-44.997-21.497-44.997-47.996V48.034c0-26.499 20.14-47.997 44.997-47.997 24.842 0 44.999 21.498 44.999 47.997v127.99c-.001 26.5-20.156 47.996-45 47.996z"/>'
  }
})
