import { RouteConfig } from "vue-router"
import Layout from '@/layout/index.vue'

const reportComment: RouteConfig = {
    path: '/report',
    component: Layout,
    children: [
      {
        path: 'report',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/system/report/index.vue'),
        meta: {
          title: '意见反馈',
          icon: 'report-suggest'
        }
      },
      {
        path: 'reportComment',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/system/report/reportComment.vue'),
        meta: {
          title: '反馈详情',
          hidden: true
        }
      }
    ]
}

export default reportComment;