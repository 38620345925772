
import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'
import { UserModule } from '@/store/modules/user'
import Breadcrumb from '@/components/Breadcrumb/index.vue'
import Hamburger from '@/components/Hamburger/index.vue'
import UserInfo from '@/views/system/user/components/UserInfo.vue'
import ChangePassword from '@/views/system/user/components/ChangePassword.vue'
import Screenfull from '@/components/Screenfull/index.vue'
import BindUser from "@/components/BindUser/index.vue";

@Component({
  name: 'Navbar',
  components: {
    Screenfull,
    Breadcrumb,
    Hamburger,
    UserInfo,
    ChangePassword,
    BindUser
  }
})
export default class extends Vue {
  dialogUserInfoVisible = false
  dialogPasswordVisible = false
  dialogBindPhoneVisible = false

  get userInfo(){
    return UserModule.currUserInfo
  }

  get opened() {
    return AppModule.opened
  }

  get device() {
    return AppModule.device.toString()
  }

  get avatar() {
    return UserModule.currUserInfo.avatar
  }

  get nickname() {
    return UserModule.currUserInfo.nickname
  }

  toggleSideBar() {
    AppModule.ToggleSideBar(false)
  }

  async logout() {
    await UserModule.LogOut().then(res=>{
      this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    });
  }

  handleCommand(command:string){
    if(command === '1'){
      this.dialogUserInfoVisible = true
    }else if(command === '2'){
      this.dialogPasswordVisible = true
    }else if(command === '3'){
      this.dialogBindPhoneVisible = true
    }
  }

  passwordSuccess(){
    setTimeout(() => {
      this.logout()
    }, 1000)
  }

  closeUserInfoDialog(){
    this.dialogUserInfoVisible = false
  }

  closePasswordDialog(){
    this.dialogPasswordVisible = false
  }
}
