/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'web-config': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M279.616 325.536v-76.48h201.12v-16h16c0-128.512-104.576-233.088-233.12-233.088S30.56 104.544 30.56 233.056c0 128.48 104.512 232.96 233.056 232.96v-16h16v-92.48m-32-322.304v73.28H109.44a196.512 196.512 0 01138.176-73.28zm32 0a196.608 196.608 0 01138.24 73.28h-138.24zM87.872 140.48h159.744v76.544H65.76a196.576 196.576 0 0122.112-76.512zm191.744 0h159.808c12.352 23.36 19.84 48.832 22.144 76.544H279.616zM65.76 249.088h181.856v76.48H87.872a196.512 196.512 0 01-22.112-76.48zm43.68 108.48h138.176v73.312A196.608 196.608 0 01109.44 357.6z" _fill="#F05542"/><path pid="1" d="M174.112 24.96c-56.192 138.368-65.504 270.816-4.16 414.528l29.44-12.576c-58.176-136.256-49.632-256.832 4.384-389.856L174.112 24.96zm177.376 0l-29.6 12.096c27.008 66.528 42.464 130.08 44.064 193.856l32-.8c-1.728-68.416-18.336-135.936-46.464-205.12z" _fill="#F05542"/><path pid="2" d="M263.616 217.056a16 16 0 00-16 16v774.912a16 16 0 0016 16H1008a16 16 0 0016-16V588.48a16 16 0 10-32 0V992H279.616V249.024H992v208a16 16 0 1032 0v-224a16 16 0 00-16-16H263.616zm744.128 279.232A16 16 0 00992 512.48v18.944a16 16 0 1032 0V512.48a16 16 0 00-16.256-16.192z" _fill="#2B4255"/><path pid="3" d="M248.128 341.056v32h775.36v-32h-775.36z" _fill="#2B4255"/><path pid="4" d="M341.184 295.04a15.52 15.488 0 01-15.52 15.488 15.52 15.488 0 01-15.488-15.52 15.52 15.488 0 0115.488-15.488 15.52 15.488 0 0115.52 15.488zm62.016 0a15.52 15.488 0 01-15.488 15.488 15.52 15.488 0 01-15.52-15.52 15.52 15.488 0 0115.52-15.488 15.52 15.488 0 0115.488 15.488zm62.048 0a15.52 15.488 0 01-15.52 15.488 15.52 15.488 0 01-15.488-15.52 15.52 15.488 0 0115.488-15.488 15.52 15.488 0 0115.52 15.488z" _fill="#F05542"/>'
  }
})
