
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { sendSmsCode } from "@/api/uaa/oauth";
import { bindPhone } from "@/api/system/user";

@Component({
  name: 'BindUser'
})
export default class extends Vue {
  @Prop({ required: true, default: false }) readonly dialogBindPhoneVisible!: boolean
  @Prop({ required: false, default: '' }) readonly userId!: string

  smsCodeLoading = false
  smsCodeTimer = 60
  interval:any

  get dialogVisible(){
    return this.dialogBindPhoneVisible;
  }

  set dialogVisible(val){
    this.$emit('update:dialogBindPhoneVisible', val)
  }

  formParam = {
    id: '',
    phone: '',
    code: ''
  }

  handleUpdateBind(){
    bindPhone(this.formParam).then(res=>{
      this.$message('绑定成功！');
      this.dialogVisible = false
    })
  }

  @Watch("dialogBindPhoneVisible")
  resetForm(){
    this.formParam = {
      id: this.userId,
      phone: '',
      code: ''
    }
    if (this.interval){
      clearTimeout(this.interval);
    }
    this.smsCodeLoading = false
    this.smsCodeTimer = 60
  }
  handleSendSmsCode(){
    sendSmsCode(this.formParam).then(res=>{
      this.loadSmsCodeTimer()
      this.$message('消息发送成功');
    })
  }
  private loadSmsCodeTimer(){
    this.interval = setInterval(() => {
      if(this.smsCodeTimer <= 0) {
        this.smsCodeLoading = false
        this.smsCodeTimer = 60
        //当倒计时结束，这里要清除定时器
        clearTimeout(this.interval);
      } else {
        this.smsCodeTimer--
      }
    }, 1000);
    this.smsCodeLoading = true
  }
}
