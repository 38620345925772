// 公钥key
const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCZwbLks6kF3z9I8gB8E8lzvgM35txTiw4OVV32GRlZ4GVXF6jV7+SQvr6hwr7+QeDq+1ElB1+490GTInOagvAjcQUys/fQ2+uHr7yqzPiyMHIuHctTRtXMK75+BNETxFbBnOqJUQIxPCgxK3/HjZerEQnUEv44c9mcXipqc92b0QIDAQAB'
// 私钥key
const privateKey = 'MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBALU43olXWtRS5aHcUQcXId1gWRE9haCpZpioAoCVgG+EgUilFtKt7cyk3VDfp5wkkdnLnVOkV3fTpADdsIIWzNW/Wx8fxJ8cnpFxE+7jlo2yGRNCdxRZx7qvGap7oaNGH+U5DE/U5RF4yDjYO5YlfSd1ymeWfABwvBTN5uba6wZpAgMBAAECgYBLJvxSZGGMb5w/FYicsiEfFxeYqLTDvbyIThRLkrQiiwa6Q4xFbk+RcEU/hqCRhjDd6yyhRSPo02HN4iGSsTXcM3ENpgnT62odauYnnWIoHipQDtFkvwcawQl0YQsmUVgDr8oBiPOfQwRsxRJ+LDPttYh9nQ6V+v2mjRqIbAbdIQJBAOVfsNU64pjCPM1pbOfJcOMEsavjCiGpYMikBW/nEzCRJJi/KcoSFsLOscgHchX52UtFS4TLzzOBAcigMc+AfAUCQQDKQkEbA0QCuHj9FDA6SqPSxJjQFFEzILBxtrgkxRmG9sBm5wyBuTmIN+LLQr9+IhkfBsQderyrO9nbT+La7pIVAkEAnmxuRYhLU7jsp++GAb23U4U9dfv1VnSXXV00SCrwrR3CiiGWqnO99sUuM3s5CAauV3fABjOCpA8ZfUlvveL/RQJAc1I5euRux7pUyb/UC7h5FfigRKwA7EzsLboH3nc/KdPROhrs554rrn5m4owJUSqx9h3Ofhx4LI3wlnpDF59FTQJBAN9Ju72bGEJCNnSdaRtHD9uBICS2Q0wpU/0SnmjVO6PrAP7uNZcil2hLW4vaaAcX46MqNoiRkSAomzSguuPbjH4='

const appkey = '2ceba8922746b95'

const appSecret = 'ualh1cltitbonaaf5t7yphnayyf5t5s4k6zaybg43w6aqx1jy515jf0adtnb0ya4'

const MAX_ENCRYPT_BLOCK = 117;
const MAX_DECRYPT_BLOCK = 128;

export {
    publicKey,
    privateKey,
    appkey,
    appSecret,
    MAX_ENCRYPT_BLOCK,
    MAX_DECRYPT_BLOCK
  };