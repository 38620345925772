/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'tasks-list': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M790.784 165.12h-62.208v-5.837c0-41.113-33.434-74.547-74.547-74.547H380.467c-41.113 0-74.547 33.434-74.547 74.547v5.837h-62.208c-63.283 0-114.74 51.507-114.74 114.79v547.072c0 63.284 51.457 114.79 114.74 114.79h547.072c63.283 0 114.79-51.506 114.79-114.79V279.91c0-63.283-51.507-114.79-114.79-114.79zM377.6 159.334a2.862 2.862 0 012.867-2.867H654.03a2.862 2.862 0 012.867 2.867v87.245a2.862 2.862 0 01-2.867 2.867H380.467a2.867 2.867 0 01-2.867-2.867v-87.245zm456.294 667.648c0 23.757-19.353 43.11-43.11 43.11H243.712c-23.757 0-43.06-19.353-43.06-43.11V279.91c0-23.756 19.303-43.11 43.06-43.11h62.208v9.728c0 41.114 33.434 74.547 74.547 74.547H654.03c41.113 0 74.547-33.433 74.547-74.547V236.8h62.208c23.757 0 43.11 19.354 43.11 43.11v547.072z" _fill="#4D4D4D"/><path pid="1" d="M481.946 688.486c-9.012 0-17.972-3.379-24.935-10.086l-124.16-120.269a35.84 35.84 0 01-.819-50.688 35.84 35.84 0 0150.688-.819l98.765 95.642 169.83-170.957c13.927-14.029 36.66-14.131 50.688-.154 14.029 13.927 14.131 36.66.154 50.688L507.392 677.888a35.738 35.738 0 01-25.446 10.598z" _fill="#5E9EFC"/>'
  }
})
