import { RouteConfig } from "vue-router"
import Layout from '@/layout/index.vue'

const finance: RouteConfig = {
    path: '/finance',
    component: Layout,
    redirect: 'noredirect',
    meta: {
        title: '财务管理',
        icon: 'finance'
    },
    children: [
      {
        path: 'withdrawConfig',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/finance/withdrawConfig/index.vue'),
        meta: {
          title: '提现配置',
          icon: 'withdraw-config'
        }
      },
      {
        path: 'withdrawLogs',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/finance/withdrawLogs/index.vue'),
        meta: {
          title: '提现管理',
          icon: 'withdraw-logs'
        }
      },
      {
        path: 'wallet',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/finance/wallet/index.vue'),
        meta: {
          title: '用户钱包',
          icon: 'wallet'
        }
      },
      {
        path: 'walletDetail',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/finance/walletDetail/index.vue'),
        meta: {
          title: '钱包记录',
          icon: 'wallet-detail'
        }
      }
    ]
}

export default finance;