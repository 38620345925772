/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'jd': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M843.186.613H178.854C80.117.613 0 80.729 0 179.466V843.8c0 98.737 80.117 178.853 178.854 178.853h664.332c98.737 0 178.854-80.116 178.854-178.853V179.466C1022.04 80.73 942.046.613 843.186.613zM397.644 592.178c0 106.7-76.197 193.31-170.156 193.31H199.19V721.05h28.298c62.599 0 113.437-57.699 113.437-128.873v-354.4h56.719v354.4zm212.665 193.31H468.45V237.9h141.736c117.48 0 212.665 122.625 212.665 273.794s-95.185 273.793-212.542 273.793zm0 0" _fill="#FF5858"/><path pid="1" d="M610.309 302.214h-85.017v418.714h85.017c86.119 0 155.945-93.714 155.945-209.357-.122-115.52-69.949-209.357-155.945-209.357zm0 0" _fill="#FF5858"/>'
  }
})
