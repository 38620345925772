import { get, post, downloadG } from '@/utils/request';

// -------接口参数约束------

export interface updateUserInfoParam {
    password?:any
    roles?:any
    sex?:any
    nickname?:any
    telephone?:any
    avatar?:any
    id?:any
    comfirm?:any
    realname?:any
}

export interface addUserParam {
    password?:any
    roles?:any
    sex?:any
    nickname?:any
    telephone?:any
    avatar?:any
    comfirm?:any
    realname?:any
    username?:any
}

export interface bindPhoneParam {
    code?:any
    phone?:any
}

export interface pageParam {
    current?:any
    size?:any
    enable?:any
    startTime?:any
    endTime?:any
    keyword?:any
}

export interface changePasswordParam {
    password?:any
    comfirm?:any
}

export interface statusParam {
    id?:any
}
// -------接口列------

/**
 * [后台]修改密码
 * @notes 
 */
export function changePassword(param:changePasswordParam) {
    return post('/system/user/changePassword', param);
}

/**
 * [后台]获取当前登录用户
 * @notes 
 */
export function getCurrentUser() {
    return get('/system/user/curUser');
}

/**
 * [后台]绑定手机号码
 * @notes 
 */
export function bindPhone(param:bindPhoneParam) {
    return post('/system/user/bindPhone', param);
}

/**
 * [后台]变更账号信息
 * @notes 
 */
export function updateUserInfo(param:updateUserInfoParam) {
    return post('/system/user/updateUserInfo', param);
}

/**
 * [后台]添加账户信息
 * @notes 
 */
export function addUser(param:addUserParam) {
    return post('/system/user/addUser', param);
}

/**
 * [后台]变更账户状态
 * @notes 
 */
export function status(param:statusParam) {
    return get('/system/user/status', param);
}

/**
 * [后台]后台管理用户信息
 * @notes 
 */
export function page(param:pageParam) {
    return get('/system/user/page', param);
}

