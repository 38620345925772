import { RouteConfig } from "vue-router"
import Layout from '@/layout/index.vue'

const taoke: RouteConfig = {
    path: '/taoke',
    component: Layout,
    redirect: 'noredirect',
    meta: {
        title: '淘客商城',
        icon: 'activity'
    },
    children: [
        {
            path: 'taobaoConfig',
            component: () => import('@/views/taoke/taobaoConfig/index.vue'),
            meta: {
                title: '淘宝配置',
                icon: 'tao'
            }
        },
        {
            path: 'jdConfig',
            component: () => import('@/views/taoke/jdConfig/index.vue'),
            meta: {
                title: '京东配置',
                icon: 'jd'
            }
        },
        {
            path: 'pddConfig',
            component: () => import('@/views/taoke/pddConfig/index.vue'),
            meta: {
                title: '拼多多配置',
                icon: 'pdd'
            }
        },
        {
            path: '3rdConfig',
            component: () => import('@/views/taoke/3rdConfig/index.vue'),
            meta: {
                title: '三方平台配置',
                icon: '3rd'
            }
        },
        {
            path: 'taobaoAuth',
            component: () => import('@/views/taoke/taobaoAuth/index.vue'),
            meta: {
                title: '淘宝用户授权',
                icon: 'role-manager'
            }
        },
        {
            path: 'orderList',
            component: () => import('@/views/taoke/taobaoAuth/index.vue'),
            meta: {
                title: '订单管理',
                icon: 'order-list'
            }
        }
    ]
}
export default taoke;