/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'api-manager': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" _fill="#333" d="M1.463 898.926q0-43.886 43.886-43.886h852.114q43.886 0 43.886 43.886 0 43.885-43.886 43.885H45.349q-43.886 0-43.886-43.885zm0-855.04Q1.463 0 45.349 0h934.765Q1024 0 1024 43.886q0 43.885-43.886 43.885H45.35q-43.886 0-43.886-43.885zM43.886 942.81Q0 942.811 0 898.926V43.886Q0 0 43.886 0 87.77 0 87.77 43.886v855.04q0 43.885-43.885 43.885zm974.263-55.588L871.863 776.777a14.629 14.629 0 00-23.406 11.703v219.429a14.629 14.629 0 0023.406 11.702l146.286-110.445a14.629 14.629 0 000-21.943zM141.166 590.994L264.777 261.12a66.56 66.56 0 0163.634-49.737 73.143 73.143 0 0166.56 49.737L512 590.994a130.926 130.926 0 005.12 23.406c0 24.137-16.091 35.84-43.154 35.84a41.691 41.691 0 01-44.617-28.526l-16.823-57.051H245.76l-19.017 57.051a45.349 45.349 0 01-45.349 28.526 36.571 36.571 0 01-42.423-38.034v-16.823a6.583 6.583 0 002.195-4.389zM328.41 306.47L271.36 482.01h114.103zM547.11 607.817V266.24a41.691 41.691 0 0144.617-46.811H701.44a146.286 146.286 0 01153.6 146.285A141.897 141.897 0 01703.634 512h-66.56v95.817a40.229 40.229 0 01-45.348 42.423c-29.989 0-44.617-13.897-44.617-42.423zm89.965-308.663v128h61.44a59.977 59.977 0 0062.172-61.44 62.903 62.903 0 00-62.172-64.365zm246.492 308.663V261.12a42.423 42.423 0 0145.348-47.543 43.154 43.154 0 0144.617 47.543v346.697a39.497 39.497 0 01-44.617 42.423c-29.988 0-45.348-13.897-45.348-42.423z"/>'
  }
})
