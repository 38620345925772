/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'activity': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M836.346 257.722h-28.428c14.415-24.442 20.742-47.755 18.72-69.38-1.677-18.098-10.254-43.982-41.686-64.611-26.06-17.143-59.572-21.348-96.751-12.06-60.293 15.138-131.65 67.18-178.567 130.074-46.947-62.893-118.246-114.936-178.538-130.074-37.21-9.349-70.751-5.114-96.807 12.06-31.405 20.629-39.984 46.513-41.66 64.612-2.021 21.624 4.246 44.937 18.72 69.379h-28.427c-43.044 0-78.074 35.056-78.074 78.132V436.49c0 34.075 22.475 64.191 54.992 74.305-3.09 8.55-4.708 17.39-4.708 26.347v301.923c0 43.046 35.07 78.117 78.117 78.117h552.858c43.045 0 78.06-35.07 78.06-78.117V537.141a76.868 76.868 0 00-4.65-26.377c32.468-10.17 54.89-40.286 54.89-74.275V335.88c-.002-43.072-35.016-78.157-78.061-78.157m-50.24 603.819h-248.68V514.636h248.681c12.395 0 22.446 10.11 22.446 22.505v301.923c-.001 12.395-10.052 22.477-22.447 22.477M568.05 257.72c39.55-45.618 91.462-81.468 133.614-92.043 21.897-5.488 39.808-3.985 52.78 4.566 13.782 9.056 16.234 17.13 16.786 23.227 2.192 22.36-21.785 52.505-33.109 64.25H568.05zm290.714 78.16v100.61c0 12.394-10.024 22.473-22.417 22.473h-298.92V313.391h298.92c12.393 0 22.417 10.098 22.417 22.49m-376.95 178.755v346.905H233.248c-12.424 0-22.478-10.083-22.478-22.477V537.141c0-12.395 10.054-22.505 22.478-22.505h248.565zM248.04 193.471c.547-6.097 3.033-14.172 16.756-23.227 12.883-8.551 30.795-10.127 52.81-4.566 41.918 10.516 93.774 46.368 133.41 92.044H281.089c-10.14-10.27-35.274-41.355-33.048-64.25M160.446 335.88c0-12.391 10.054-22.49 22.475-22.49h298.891v145.573H182.921c-12.421 0-22.475-10.08-22.475-22.474V335.88z" _fill="#272536"/>'
  }
})
