import { RouteConfig } from "vue-router"
import Layout from '@/layout/index.vue'

const systemManager: RouteConfig = {
    path: '/system',
    component: Layout,
    redirect: 'noredirect',
    meta: {
        title: '系统管理',
        icon: 'system-manage'
    },
    children: [
      {
        path: 'user',
        component: () => import('@/views/system/user/index.vue'),
        meta: {
          title: '用户管理',
          icon: 'user-manager'
        }
      },
      {
        path: 'role',
        component: () => import('@/views/system/role/index.vue'),
        meta: {
          title: '角色管理',
          icon: 'role-manager'
        }
      },
      {
        path: 'apis',
        component: () => import('@/views/system/apis/index.vue'),
        meta: {
          title: 'API管理',
          icon: 'api-manager'
        }
      },
      {
        path: 'menu',
        component: () => import('@/views/system/menu/index.vue'),
        meta: {
          title: '菜单管理',
          icon: 'menu-manager'
        }
      },
      {
        path: 'appConfig',
        component: () => import('@/views/system/appConfig/index.vue'),
        meta: {
          title: '应用管理',
          icon: 'app-config'
        }
      },
      {
        path: 'authlogs',
        component: () => import('@/views/system/authLogs/index.vue'),
        meta: {
          title: '请求日志',
          icon: 'logs'
        }
      },
      {
        path: 'iconManager',
        component: () => import('@/views/system/iconManager/index.vue'),
        meta: {
          title: '图标管理',
          icon: 'icon'
        }
      }
    ]
}

export default systemManager;