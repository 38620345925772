/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'withdraw-logs': {
    width: 201.172,
    height: 200,
    viewBox: '0 0 1030 1024',
    data: '<path pid="0" d="M55.296 573.44q-23.552 0-40.448-17.408t-16.896-40.96v-77.824q0-23.552 16.896-40.448t40.448-16.896h751.616q-15.36 4.096-29.696 10.752t-25.6 20.992q-32.768 36.864-65.536 79.36t-62.464 82.432H55.296zm128-259.072q-23.552 0-40.448-17.408T125.952 256v-71.68q0-23.552 16.896-40.448t40.448-16.896H962.56q23.552 0 40.96 16.896t17.408 40.448V256q0 23.552-17.408 40.96t-40.96 17.408H183.296zm517.12 449.536v8.192q0 6.144.512 19.968t.512 40.448H55.296q-23.552 0-40.448-16.896t-16.896-41.472v-76.8q0-23.552 16.896-40.448T55.296 640h517.12q-3.072 2.048-5.632 5.632t-4.608 5.632q-10.24 14.336-12.288 33.792t4.096 37.376 19.968 30.208 34.304 12.288h48.128zm305.152-114.688l15.36 25.6q6.144 10.24 6.144 16.384t-7.68 8.704-25.088 2.56h-55.296q-22.528 0-33.792 11.264t-11.264 29.696v99.328q0 17.408-15.36 34.816t-36.864 17.408h-39.936q-15.36 0-27.136-11.776t-11.776-41.472q0-13.312.512-16.896t.512-9.216.512-18.944.512-46.08q0-25.6-8.704-36.864t-31.232-11.264h-55.296q-23.552 0-29.696-9.728t10.24-30.208q15.36-20.48 34.304-47.616t38.912-56.32 38.912-56.832 36.352-49.152q7.168-8.192 18.432-12.8t23.552-5.12 22.528 2.56 15.36 10.24q12.288 21.504 28.16 47.616t33.28 52.736 34.304 52.736 31.232 48.64z"/>'
  }
})
