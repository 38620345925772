import axios, { AxiosRequestConfig } from 'axios'
import { MessageBox, Message } from 'element-ui'
import { hideLoading, showLoading } from './loading'
import { rsaSignData } from './encrypt'
import { UserModule } from '@/store/modules/user'
import { appkey } from './encryptConfig'

const enableEncrypt = process.env.VUE_APP_ENCRYPT_ENABLE
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000, // request timeout
  headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'appKey': appkey
  }
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (config.headers && UserModule.token) {
      config.headers.Token = UserModule.token
    }
    const timestamp = Date.parse(new Date().toString());
    let signBody 
    if(config.method === 'get'){
      config.params.timestamp = timestamp;
      signBody = config.params;
    } else {
      config.data.timestamp = timestamp;
      signBody = config.data;
    }
    if(config.headers){
      config.headers.sign = rsaSignData(signBody);
      config.headers.timestamp = timestamp;
    }
    //无论是get还是post都在url上加时间戳，防终端缓存
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    // download response just return
    if (response.config.responseType === 'blob' || Array.isArray(res)) {
      return response;
    }
    // if the custom code is not 200, it is judged as an error.
    if (res.code === 401) {
      if(UserModule.isAlertNoLogin) return Promise.reject(new Error(res.message || 'Error'));
      UserModule.setNoLoginAlert(true)
      // to re-login
      MessageBox.confirm('当前账户已退出登录，是否需要重新登录？', '重新登录', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        UserModule.ResetToken()
        location.reload()
      }).finally(()=>{
        UserModule.setNoLoginAlert(false)
      })
      return Promise.reject(new Error(res.message || 'Error'))
    } else if (res.code !== 200) {
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      Message({
        message: res.msg || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(res.msg || 'Error')
    } else {
      const refreshToken = response.headers.refresh_token;
      if(refreshToken){
        UserModule.RefreshToken(refreshToken)
      }
      return res
    }
  },
  error => {
    Message({
      message: error.message || 'SYSTEM ERROR!!!',
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

function packageReq(isShowLoading:boolean, reqConfig:AxiosRequestConfig) {
  isShowLoading && showLoading();
  const reqPromise = service.request(reqConfig)
  reqPromise.finally(() => {
    isShowLoading && hideLoading();
  })
  return reqPromise;
}


const post = function(url:string, data:any = {}, config:AxiosRequestConfig = {}, isShowLoading = true) {
  return packageReq(isShowLoading, {
    method: 'post',
    url,
    data,
    ...config
  })
}

const get = function(url:string, data:any = {}, config:AxiosRequestConfig = {}, isShowLoading = true) {
  return packageReq(isShowLoading, {
    method: 'get',
    url,
    params: data,
    ...config
  })
}

const download = function(url:string, method:string, params: any = {}, config: AxiosRequestConfig, isShowLoading: boolean) {
  packageReq(isShowLoading, {
    method,
    url,
    params,
    responseType: 'blob',
    ...config
  }).then(res=>{
    resolveBlob(res)
  }).catch((error) => {
    Message({
      message: error,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  })
}

const downloadG = function(url:string, params:any = {}, config:AxiosRequestConfig = {}, isShowLoading = true) {
  return download(url, "get", params, config, isShowLoading);
}

/**
 * 解析blob响应内容并下载
 * @param {*} res blob响应内容
 * @param {String} mimeType MIME类型
 */
const resolveBlob = function(res:any) {
    const blob = new Blob([res.data]);
    const downloadElement = document.createElement("a");
    const href = window.URL.createObjectURL(blob);
    const patt = new RegExp('filename=([^;]+\\.[^\\.;]+);*')
    const contentDisposition = decodeURI(res.headers['content-disposition'])
    const result = patt.exec(contentDisposition)
    let fileName = result ? result[1] : (Date.parse(new Date().toString()) / 1000) + ".data"
    fileName = fileName.replace('"', '')
    //后台再header中传文件名
    downloadElement.href = href;
    downloadElement.download = fileName;
    document.body.appendChild(downloadElement);
    downloadElement.click();
    document.body.removeChild(downloadElement); // 下载完成移除元素
    window.URL.revokeObjectURL(href); // 释放掉blob对象
}

export {
  post,
  get,
  downloadG
};