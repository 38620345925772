/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'check-code': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M964.488 357.349c-4.886 70.287-4.001 141.573-15.954 210.638-20.436 118.087-85.131 211.523-181.248 282.803-66.926 49.632-134.379 98.542-201.627 147.744-30.01 21.956-60.986 23.348-89.619 2.313-76.064-55.874-153.24-110.382-227.13-169.024-94.745-75.195-153.822-174.919-163.748-295.15-9.22-111.697-5.526-224.52-5.772-336.852-.056-24.462 15.402-43.942 39.332-51.668 127.702-41.235 255.465-82.312 383.843-121.367 16.21-4.933 37.126-.844 54.17 4.477 118.446 36.983 236.472 75.302 354.56 113.421 32.716 10.561 48.404 32.302 48.547 66.604.2 48.583.051 97.166.051 145.748 1.535.103 3.065.21 4.595.313zm-840.91 1.622c0 37.51-.604 75.036.107 112.536 2.564 135.136 57.599 245.743 162.06 329.84C351.91 854.614 422.869 901.892 490.884 952.9c20.4 15.299 36.871 14.966 57.087-.277 63.805-48.112 129.089-94.259 192.878-142.386 100.865-76.106 162.588-176.398 170.698-303.302 5.863-91.7 2.604-183.99 3.3-276.024.184-24.667-12.367-38.405-35.93-45.886-111.763-35.469-223.076-72.36-334.983-107.363-14.46-4.523-32.685-5.05-47.032-.578-112.767 35.156-224.899 72.344-337.501 108.023-24.924 7.895-36.155 22.912-35.899 48.537.42 41.777.118 83.555.077 125.328z"/><path pid="1" d="M519.537 307.292c70.753-.015 141.512-.082 212.265.041 13.616.026 29.79-1.74 28.577 19.188-.998 17.186-10.986 24.723-29.002 24.467-51.99-.741-104.052 1.142-155.976-.844-26.203-1.003-33.955 7.977-33.724 33.264.972 106.55.64 213.12.015 319.674-.056 10.003-2.911 22.427-9.297 29.114-4.385 4.595-19.31 4.503-25.512.415-5.771-3.802-8.616-15.652-8.662-23.977-.548-108.264-.338-216.528-.384-324.791-.015-32.89-.159-33.034-32.644-33.085-49.443-.082-98.89-.02-148.333-.025-5.116 0-11.911 2.041-15.022-.466-8.79-7.087-20.615-14.946-22.933-24.514-3.884-16.03 10.269-18.456 23.25-18.45 72.462.025 144.92.004 217.382-.01z"/>'
  }
})
