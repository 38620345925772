/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'wallet-detail': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M768 64a96 96 0 0196 96v704a96 96 0 01-96 96H256a96 96 0 01-96-96V160a96 96 0 0196-96h512zm0 64H256a32 32 0 00-32 32v704a32 32 0 0032 32h512a32 32 0 0032-32V160a32 32 0 00-32-32zM661.6 343.68a32 32 0 01-3.936 45.12L548.928 480H640a32 32 0 0131.776 28.256L672 512a32 32 0 01-28.256 31.776L640 544h-96v64h96a32 32 0 0131.776 28.256L672 640a32 32 0 01-28.256 31.776L640 672h-96v89.056a32 32 0 01-28.256 31.776l-3.744.224a32 32 0 01-31.776-28.256l-.224-3.744V672h-96a32 32 0 01-31.776-28.256L352 640a32 32 0 0128.256-31.776L384 608h96v-64h-96a32 32 0 01-31.776-28.256L352 512a32 32 0 0128.256-31.776L384 480h98.432l-108.704-91.2a32 32 0 01-6.336-41.888l2.368-3.2a32 32 0 0141.856-6.368l3.232 2.4L515.68 424.32l100.832-84.576a32 32 0 0145.12 3.936z" _fill="#1C1C1E"/><path pid="1" d="M352 288h320a32 32 0 000-64H352a32 32 0 000 64z" _fill="#FAAC08"/>'
  }
})
