import { Loading } from 'element-ui';

const loadingOption : {
  loading: any
  timeout: number
  timer: any
} = {
  loading: null,
  timeout: 0,
  timer: null
}

/**
 * loading
 * @param option:{
 *   message: '显示的内容',
 *   className: '作用的区域的类名',
 *   lazy: '延时显示时间(s)',
 * }
 */
export function showLoading(option = { message: '加载中', className: 'main-box', lazy: 280 }) {
  loadingOption.timeout = option.lazy;
  hideLoading();

  const dialogs = document.getElementsByClassName('el-dialog__wrapper');
  let targetDialog = null;
  if (dialogs) {
    for (let i = 0; i < dialogs.length; i++) {
      const dialog = dialogs[i] as HTMLElement;
      if (dialog.style.display !== 'none') {
        targetDialog = dialog;
      }
    }
  }

  const target = targetDialog || document.querySelector('.' + option.className) as HTMLElement || document.body;
  loadingOption.timer = setTimeout(() => {
    loadingOption.loading = Loading.service({
      target,
      lock: true,
      text: option.message,
      background: 'rgba(0, 0, 0, 0.7)'
    })
  }, option.lazy)
}

export function hideLoading() {
  loadingOption.timer && clearTimeout(loadingOption.timer);
  loadingOption.loading && loadingOption.loading.close();
}
