/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'phone': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M511.675 947.281a70.055 70.055 0 10-70.055-69.567 70.217 70.217 0 0070.055 69.567zm0-91.347a21.293 21.293 0 11-21.293 21.78 21.293 21.293 0 0121.293-21.78z" _fill="#0074FF"/><path pid="1" d="M808.635 759.71V83.383A83.545 83.545 0 00725.252 0H298.748a83.545 83.545 0 00-83.383 83.383v857.234A83.545 83.545 0 00298.748 1024h426.504a83.545 83.545 0 0083.383-83.383V759.71zM298.748 48.762h426.504a34.621 34.621 0 0134.621 34.62V735.33H264.127V83.383a34.621 34.621 0 0134.62-34.621zm461.125 891.855a34.621 34.621 0 01-34.62 34.621H298.747a34.621 34.621 0 01-34.621-34.62V784.09h495.746z" _fill="#0074FF"/>'
  }
})
