/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'tasks': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M905.165 151.603H701.44v-40.14a30.72 30.72 0 10-61.44 0v40.14H383.18v-40.14a30.72 30.72 0 10-61.44 0v40.14H122.88a30.72 30.72 0 00-30.72 30.72v707.123a30.72 30.72 0 0030.72 30.72h782.285a30.72 30.72 0 0030.72-30.72V182.323a30.72 30.72 0 00-30.72-30.72zm-30.72 707.123H153.6V213.043h168.14v67.277a30.72 30.72 0 0061.44 0v-67.277H640v67.277a30.72 30.72 0 1061.44 0v-67.277h172.902z" _fill="#37313F"/><path pid="1" d="M555.06 437.709h221.644v61.44H555.059zm0 202.65h221.644v61.44H555.059zM341.3 765.541l-88.474-85.81 42.752-44.084 42.65 41.318 86.834-96.665 45.67 41.062L341.3 765.543zm0-213.401l-88.474-85.811 42.752-44.084 42.65 41.37 86.834-96.666 45.67 41.012L341.3 552.14z" _fill="#FFBD5C"/>'
  }
})
