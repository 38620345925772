/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'withdraw-config': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M932.098 994.668H92.236c-50.764 0-92.063-41.3-92.063-92.063V343.313c0-50.764 41.299-92.063 92.063-92.063h839.862c50.764 0 92.063 41.299 92.063 92.063v132.463c0 18.33-14.859 33.189-33.189 33.189H804.786c-62.856 0-113.993 51.138-113.993 113.993s51.137 113.993 113.993 113.993h186.185c18.33 0 33.189 14.859 33.189 33.189v132.464c0 50.764-41.299 92.064-92.062 92.064zM92.236 317.63c-14.162 0-25.684 11.521-25.684 25.683v559.292c0 14.161 11.521 25.683 25.684 25.683h839.862c14.161 0 25.683-11.521 25.683-25.683v-99.274H804.786c-99.458 0-180.373-80.915-180.373-180.372s80.915-180.372 180.373-180.372H957.78v-99.274c0-14.162-11.521-25.683-25.683-25.683H92.236z"/><path pid="1" d="M885.553 622.959c0 44.705-36.244 80.95-80.95 80.95-44.707 0-80.951-36.245-80.951-80.95 0-44.711 36.244-80.951 80.951-80.951 44.706 0 80.95 36.24 80.95 80.951zM826.692 96.155c5.802-1.686 11.897 1.662 13.584 7.464l35.816 123.183h69.128L904.016 85.087c-11.905-40.95-54.904-64.575-95.855-52.672L139.596 226.802h237.756l449.34-130.647z"/>'
  }
})
