/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'realname-auth-config': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M960 896H64c-35.2 0-64-28.8-64-64V192c0-35.2 28.8-64 64-64h896c35.2 0 64 28.8 64 64v640c0 35.2-28.8 64-64 64zm32-704c0-19.2-12.8-32-32-32H64c-19.2 0-32 12.8-32 32v640c0 19.2 12.8 32 32 32h896c19.2 0 32-12.8 32-32V192zM576 416h320v32H576v-32zm0-96h320v32H576v-32zm41.6 307.2l80 41.6 153.6-118.4c9.6-9.6 25.6-9.6 35.2 0 9.6 9.6 9.6 25.6 0 35.2L713.6 758.4c-3.2 6.4-9.6 9.6-16 9.6s-12.8-3.2-19.2-6.4l-99.2-99.2c-9.6-9.6-9.6-25.6 0-35.2 12.8-12.8 28.8-12.8 38.4 0zM512 720c0 6.4 0 9.6-3.2 16h-32c0-6.4 3.2-9.6 3.2-16 0-80-70.4-144-160-144s-160 64-160 144c0 6.4 0 9.6 3.2 16h-32c-3.2-6.4-3.2-9.6-3.2-16 0-76.8 51.2-137.6 124.8-163.2-35.2-22.4-60.8-64-60.8-108.8 0-70.4 57.6-128 128-128s128 57.6 128 128c0 44.8-25.6 86.4-60.8 108.8C460.8 582.4 512 643.2 512 720zm-96-272c0-54.4-41.6-96-96-96s-96 41.6-96 96 41.6 96 96 96 96-41.6 96-96z" _fill="#666"/>'
  }
})
