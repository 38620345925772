import { get, post, downloadG } from '@/utils/request';

// -------接口参数约束------

export interface getUsernameTokenParam {
    password?:any
    code?:any
    key?:any
    username?:any
}

export interface getAppSmsTokenParam {
    code?:any
    phone?:any
}

export interface sendSmsCodeParam {
    phone?:any
}

export interface retrievePasswordParam {
    password?:any
    code?:any
    phone?:any
    comfirm?:any
}

export interface getAppWxTokenParam {
    code?:any
}
// -------接口列------

/**
 * [APP]微信授权登录
 * @notes 
 */
export function getAppWxToken(param:getAppWxTokenParam) {
    return get('/uaa/oauth/getAppWxToken', param);
}

/**
 * [APP]发送短信验证码
 * @notes 
 */
export function sendSmsCode(param:sendSmsCodeParam) {
    return post('/uaa/oauth/sendSmsCode', param);
}

/**
 * [APP]图形验证码获取
 * @notes 
 */
export function getCaptchaCode() {
    return get('/uaa/oauth/captchaCode');
}

/**
 * [APP]退出登录
 * @notes 
 */
export function logoutAccount() {
    return post('/uaa/oauth/logout');
}

/**
 * [APP]账号密码登录
 * @notes 
 */
export function getUsernameToken(param:getUsernameTokenParam) {
    return get('/uaa/oauth/getUsernameToken', param);
}

/**
 * [APP]短信验证码登录
 * @notes 
 */
export function getAppSmsToken(param:getAppSmsTokenParam) {
    return get('/uaa/oauth/getAppSmsToken', param);
}

/**
 * [APP]用户找回密码
 * @notes 
 */
export function retrievePassword(param:retrievePasswordParam) {
    return post('/uaa/oauth/retrievePassword', param);
}

