/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'app-config': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M516.352 974.08c-13.312 0-26.624-3.328-38.656-10.24L141.824 773.12c-24.32-13.824-39.424-39.68-39.68-67.584l-2.816-386.304c-.256-27.904 14.592-54.016 38.656-68.096L471.04 55.552c24.064-14.08 54.016-14.336 78.336-.512l341.248 192c23.04 13.056 37.376 37.632 37.376 64v391.936c0 25.856-13.824 50.176-36.352 63.488l-335.872 197.12c-11.776 6.912-25.6 10.496-39.424 10.496zM163.584 705.024c0 6.144 3.328 11.776 8.704 14.592l335.872 190.72c5.376 3.072 11.776 2.816 16.896 0l335.872-197.12c3.584-2.048 5.888-6.144 5.888-10.496V311.04c0-4.352-2.304-8.448-6.144-10.496l-341.248-192c-5.376-3.072-11.776-3.072-17.152 0L169.216 304.128c-5.12 3.072-8.448 8.704-8.448 14.848l2.816 386.048z" _fill="#080103"/><path pid="1" d="M510.976 534.016c-5.12 0-9.984-1.28-14.592-3.584L237.312 390.4c-14.848-8.192-20.48-26.624-12.544-41.728 8.192-14.848 26.624-20.48 41.728-12.544L510.72 468.224l248.832-138.24c14.848-8.192 33.536-2.816 41.728 12.032 8.192 14.848 2.816 33.536-12.032 41.728L525.824 530.176c-4.352 2.56-9.472 3.84-14.848 3.84z" _fill="#D63123"/><path pid="2" d="M513.024 832.256c-16.896 0-30.72-13.568-30.72-30.464l-2.048-297.984c0-16.896 13.568-30.72 30.464-30.976h.256c16.896 0 30.72 13.568 30.72 30.464l2.048 297.984c0 16.896-13.568 30.72-30.72 30.976.256 0 0 0 0 0z" _fill="#D63123"/>'
  }
})
