import { RouteConfig } from "vue-router"
import Layout from '@/layout/index.vue'

const tasks: RouteConfig = {
    path: '/tasks',
    component: Layout,
    redirect: 'noredirect',
    meta: {
        title: '任务管理',
        icon: 'tasks'
    },
    children: [
      {
        path: 'tasksList',
        component: () => import('@/views/tasks/tasksList/index.vue'),
        meta: {
          title: '任务列表',
          icon: 'tasks-list'
        }
      },
      {
        path: 'tasksReceive',
        component: () => import('@/views/tasks/tasksReceive/index.vue'),
        meta: {
          title: '领取记录',
          icon: 'reward'
        }
      },
      {
        path: 'tasksSignIn',
        component: () => import('@/views/tasks/tasksSignIn/index.vue'),
        meta: {
          title: '签到任务',
          icon: 'task-sign'
        }
      }
    ]
}

export default tasks;