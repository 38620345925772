import { RouteConfig } from "vue-router"
import Layout from '@/layout/index.vue'

const dashboard: RouteConfig = {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        component: () => import('@/views/dashboard/index.vue'),
        meta: {
          title: 'Dashboard',
          icon: 'dashboard',
          affix: true
        }
      }
    ]
}

export default dashboard;