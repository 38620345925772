import { RouteConfig } from "vue-router"
import Layout from '@/layout/index.vue'

const setting: RouteConfig = {
    path: '/setting',
    component: Layout,
    redirect: 'noredirect',
    meta: {
        title: '基础设置',
        icon: 'setting'
    },
    children: [
      {
        path: 'testAccount',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/setting/testAccount/index.vue'),
        meta: {
          title: '测试账号管理',
          icon: 'switch-set'
        }
      },
      {
        path: 'protocolRules',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/setting/protocolRules/index.vue'),
        meta: {
          title: '协议规则',
          icon: 'protocol-rules'
        }
      },
      {
        path: 'switchSet',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/setting/switchSet/index.vue'),
        meta: {
          title: '功能开关',
          icon: 'switch-set'
        }
      },
      {
        path: 'dividendSet',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/setting/dividendSet/index.vue'),
        meta: {
          title: '分佣配置',
          icon: 'dividend'
        }
      },
      {
        path: 'shareConfig',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/setting/share/index.vue'),
        meta: {
          title: '分享配置',
          icon: 'share'
        }
      },
      {
        path: 'versionConfig',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/setting/version/index.vue'),
        meta: {
          title: '版本配置',
          icon: 'version-manager'
        }
      },
      {
        path: 'adminWebConfig',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/setting/adminWeb/index.vue'),
        meta: {
          title: '后台Web配置',
          icon: 'web-config'
        }
      }
    ]
}

export default setting;