/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'permission-manager': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" _fill="#333" d="M227.556 277.504v186.88a341.333 341.333 0 00140.572 276.025L512 845.084 655.872 740.41a341.333 341.333 0 00140.572-276.025v-186.88L512 169.415 227.556 277.504zm-56.89-39.253L512 108.658l341.333 129.706v226.077a398.222 398.222 0 01-164.01 321.991L512 915.456 334.677 786.489a398.222 398.222 0 01-164.01-322.105V238.251zM512 540.444a113.778 113.778 0 110-227.555 113.778 113.778 0 010 227.555zm0-56.888a56.889 56.889 0 100-113.778 56.889 56.889 0 000 113.778zM512 512q28.444 0 28.444 28.444v145.864q0 28.444-28.444 28.444t-28.444-28.444V540.444Q483.556 512 512 512zm85.333 101.888q0 28.444-28.444 28.444h-54.045q-28.444 0-28.444-28.444t28.444-28.444h54.045q28.444 0 28.444 28.444z"/>'
  }
})
