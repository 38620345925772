
import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { DeviceType, AppModule } from '@/store/modules/app'
import { AppMain, Navbar, Sidebar } from './components'
import ResizeMixin from './mixin/resize'
import TagsView from './components/TagsView/index.vue'

@Component({
  name: 'Layout',
  components: {
    AppMain,
    Navbar,
    Sidebar,
    TagsView
  }
})
export default class extends mixins(ResizeMixin) {
  get classObj() {
    return {
      hideSidebar: !this.opened,
      openSidebar: this.opened,
      withoutAnimation: this.withoutAnimation,
      mobile: this.device === DeviceType.Mobile
    }
  }

  get needTagsView(){
    return AppModule.setting.needTagsView
  }

  handleClickOutside() {
    AppModule.CloseSideBar(false)
  }
}
