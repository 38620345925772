
import { Component, Vue } from 'vue-property-decorator'
import screenfull from 'screenfull'

@Component({
  name: 'Screenfull'
})
export default class Screenfull extends Vue {

  isFullscreen = false

  mounted() {
    this.init()
  }

  beforeDestroy() {
    this.destroy()
  }

  click() {
    if (!screenfull) {
      this.$message({
        message: 'you browser can not work',
        type: 'warning'
      })
      return false
    }
    screenfull.toggle()
  }

  change() {
    this.isFullscreen = screenfull.isFullscreen
  }

  init() {
    if (screenfull.isEnabled) {
      screenfull.on('change', this.change)
    }
  }

  destroy() {
    if (screenfull.isEnabled) {
      screenfull.off('change', this.change)
    }
  }
}
