import { RouteConfig } from "vue-router"
import Layout from "@/layout/index.vue";

const userManager: RouteConfig = {
    path: '/user',
    component: Layout,
    redirect: 'noredirect',
    meta: {
        title: '用户管理',
        icon: 'tasks'
    },
    children: [
        {
            path: 'userManager',
            component: () => import(/* webpackChunkName: "dashboard" */ '@/views/user/userManager/index.vue'),
            meta: {
                title: '用户列表',
                icon: 'user-manager'
            }
        },
        {
            path: 'realnameAuthConfig',
            component: () => import(/* webpackChunkName: "dashboard" */ '@/views/user/realnameAuthConfig/index.vue'),
            meta: {
                title: '实名认证配置',
                icon: 'realname-auth-config'
            }
        },
        {
            path: 'realnameAuthAudit',
            component: () => import(/* webpackChunkName: "dashboard" */ '@/views/user/realnameAuthAudit/index.vue'),
            meta: {
                title: '实名认证审核',
                icon: 'realname-auth-audit'
            }
        },
        {
            path: 'realnameAuthLogs/:userId',
            component: () => import(/* webpackChunkName: "dashboard" */ '@/views/user/realnameAuthLogs/index.vue'),
            meta: {
                title: '实名认证记录',
                hidden: true
            }
        }
    ]
}
export default userManager;