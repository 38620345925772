/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'icon': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M871.424 61.44q18.432 0 34.816 6.656t28.672 18.944 19.456 28.672 7.168 35.84v720.896q0 38.912-25.088 64.512t-62.976 25.6h-721.92q-40.96 0-66.048-26.624t-25.088-66.56V150.528q0-35.84 24.576-62.464t65.536-26.624h720.896zm-237.568 768q18.432 0 26.624-8.704t8.192-23.04q0-13.312-8.192-22.528t-26.624-9.216h-62.464q1.024-2.048 1.024-6.144V251.904h63.488q18.432 0 25.6-9.216t7.168-22.528-7.168-22.528-25.6-9.216H386.048q-18.432 0-25.6 9.216t-7.168 22.528 7.168 22.528 25.6 9.216h57.344v507.904q0 2.048.512 3.072t.512 3.072h-56.32q-18.432 0-26.624 9.216t-8.192 22.528q0 14.336 8.192 23.04t26.624 8.704h245.76z"/>'
  }
})
