
import { changePassword } from '@/api/system/user';
import { cloneDeep } from '@/utils/common';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
  name: 'ChangePassword'
})
export default class ChangePassword extends Vue {
  @Prop({
    required: true,
    default: false
  }) readonly dialogPasswordVisible!: boolean;
  @Prop({ required: true }) readonly infoParams!: any;

  formParam = {
    id: null,
    username: null,
    password: "",
    comfirm: ""
  }

  get infoTitle() {
    return "修改密码-【" + this.formParam.username + "】";
  }

  @Emit("closePasswordDialog")
  closePasswordDialog() {
  }

  @Emit("success")
  successOn() {
  }

  handleUpdatePassword() {
    changePassword(this.formParam).then(() => {
      this.$message({
        type: 'success',
        message: '操作成功!'
      });
      this.closePasswordDialog()
      this.successOn()
    })
  }

  @Watch("infoParams")
  infoParamsChange() {
    this.formParam = cloneDeep(this.infoParams)
  }
}
