import { get, post, downloadG } from '@/utils/request';

// -------接口参数约束------

export interface loginAccountParam {
    password?:any
    code?:any
    key?:any
    username?:any
}

export interface adminSmsTokenParam {
    phone?:any
    smsCode?:any
}
// -------接口列------

/**
 * [后台]图形验证码获取
 * @notes 图形验证码获取
 */
export function authCode() {
    return get('/uaa/member/codeImg');
}

/**
 * [后台]短信验证码登录
 * @notes 
 */
export function adminSmsToken(param:adminSmsTokenParam) {
    return post('/uaa/member/adminSmsToken', param);
}

/**
 * [后台]退出登录
 * @notes 退出登录
 */
export function logoutAccount() {
    return post('/uaa/member/logout');
}

/**
 * [后台]账号密码登录
 * @notes 账号密码登录
 */
export function loginAccount(param:loginAccountParam) {
    return post('/uaa/member/login', param);
}

