
//深度复制对象
export function cloneDeep(initObj:any) {
    if (typeof initObj !== 'object') {
      return initObj
    }
    const obj:any = Array.isArray(initObj) ? [] : {};
    for (const key in initObj) {
      if (initObj[key] === null) {
        obj[key] = initObj[key];
      } else if (typeof initObj[key] === 'object') {
        //对数组特殊处理
        if (Array.isArray(initObj[key])) {
          //用map方法返回新数组，将数组中的元素递归
          obj[key] = initObj[key].map((item:any) => cloneDeep(item))
        } else {
          //递归返回新的对象
          obj[key] = cloneDeep(initObj[key]);
        }
      } else if (typeof initObj[key] === 'function') {
        //返回新函数
        obj[key] = initObj[key].bind(obj);
      } else {
        //基本类型直接返回
        obj[key] = initObj[key];
      }
    }
    return obj;
  }